import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/fat/fatcontent.js";
import Fatcon from "../sections/fat/fatcon.js";
import Bef from "../sections/fat/fatbefore.js";
import Faq from "../sections/fat/fatfaq.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/index/indexreviews.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/fat.png";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"


const FatFillerPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
            <Head title="Fat Dissolving London" image="/images/dermamina-home.jpg"  description="Achieve a slimmer silhouette with our non-surgical fat dissolving treatment. Safely reduce stubborn fat with our effective, non-invasive Fat Dissolving treatment. Book now!" keywords="fat dissolving treatment, non-surgical fat reduction, fat reduction injections, fat dissolving injections, body contouring, fat melting treatment, lipolysis treatment, fat reduction procedure, cosmetic fat dissolving, non-invasive fat reduction"/>

            <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>
       
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Fat </span> <span style="color: #00aec7;">Dissolving</span>'
    secondText="Goodbye Stubborn Fat"
    videoAlt="Fat Dissolving"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="2 - 3 treatments"
  bestalt="best Fat Dissolving"
  resultstext="Permanent"
  resultsalt="Fat Dissolving results"
  costdata="From £290"
  costalt="Fat Dissolving Cost"
  timedata="20 - 60 Minutes"
  timealt="Fat Dissolving Duration Time"
  workdata="Immediately"
  workalt="Fat Dissolving downtime work"
  paindata="None / Mild"
  painalt="Fat Dissolving Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Bef />
<PopupForm
  isModalOpen={isModalOpen}
  setIsModalOpen={setIsModalOpen}
/>
<Content setIsModalOpen={setIsModalOpen}/>
<Testimonial />
<Faq setIsModalOpen={setIsModalOpen} />
<Fatcon />
<Clinic /> 
<Saveface />
<Trend />
      </PageWrapper>
     
    </>
  );
};
export default FatFillerPage;
